<template>
  <div>
    <!--begin::Dashboard-->
    
    <!--begin::Row-->
    <AdminDashboard v-if="role == 1" />
    <DoctorDashboard v-if="role == 3"></DoctorDashboard>
    <PatientDashboard v-if="role == 4" />
    <DrawerChat v-if="$root.chatOpen" />


    <!--end::Row-->
    <!--end::Dashboard-->
  </div>
</template>

<script>
export default {
  name: "dashboard",
  components: {
    AdminDashboard : () => import('../pages/admin/Dashboard/Dashboard'),
    DoctorDashboard : () => import('@/view/pages/dash/doctor/doctor-dashboard.vue'),
    PatientDashboard : () => import('@/view/pages/dash/patient/PatientDashboard.vue'),
    DrawerChat : () => import('../Chat/DrawerChat')
  },
  data() {
    return {
      appbar: "",
      role: ""
    };
  },
  mounted() {
    this.$root.title = this.$t("MENU.dashboard");
    //this.fetchSettings();
    console.log("payment env", JSON.parse(localStorage.SETTINGS).payment_env);
    if(JSON.parse(localStorage.SETTINGS).payment_env)
    {
      let square = document.createElement('script')
      square.setAttribute('type', 'text/javascript');
      square.setAttribute('src', this.$root.ssdkurl_prod);
      document.head.appendChild(square)
    }
    else
    {
      let square = document.createElement('script')
      square.setAttribute('type', 'text/javascript');
      square.setAttribute('src', this.$root.ssdkurl_test);
      document.head.appendChild(square)
    }
    this.getDepartment();
    this.role = JSON.parse(localStorage.userInfo).role;
  },
  activated()
  {
    this.$root.title = this.$t("MENU.dashboard");
  },
  methods: {
    async fetchSettings() {
      await this.$api.get("items", "settings/1").then(
        ({ data }) => {
          //console.log(data);
          //console.log('call duration is ', data.data['call_duration']);
          window.localStorage.setItem("SETTINGS", JSON.stringify(data.data));
          //console.log("call duration", JSON.parse(localStorage.SETTINGS).call_duration);
        },
        error => {
          console.error(error);
        }
      );
    },
    async getDepartment() {
      var userInfo = JSON.parse(localStorage.userInfo).id;
      if (userInfo.role == 3) {
        this.$api.get(
          "items",
          "doctor/" + userInfo + "?fields=department"
        ).then(
          ({ data }) => {
            window.localStorage.setItem("DEPARTMENT", data.data.department);
          },
          error => {
            console.error(error);
          }
        );
      }
    }
  }
};
</script>
